import {SideNavInterface} from '../../interfaces/side-nav.type';

export const prefix = '/hv';

export const ROUTES: SideNavInterface[] = [
  {
    path:  prefix + '/objects',
    title: 'Objekte',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'home',
    submenu: []
  },
  {
    path: prefix + '/tickets',
    title: 'Tickets',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'inbox',
    submenu: []
  },
];
