import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {
  Appuser_Users_Objects,
  PropertyManagementRequest, PropertyManagementRequestAction,
  Ticket,
  TicketNote,
  User
} from '../../../../../database-models';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HausverwaltungApiService {

  private apiURL = environment.apiURL;
  public headers;

  constructor(public http: HttpClient, public autenticationService: AuthenticationService) {
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    this.autenticationService.session.subscribe((session) => {
      this.headers = new HttpHeaders()
        .set('apikey', session);
    });
  }

  public getObjects(id?: string): Observable<any> {
    if (!id) {
      return this.http.get(this.apiURL + '/hv/object', {headers: this.headers});
    } else {
      return this.http.get(this.apiURL + '/hv/object/' + id, {headers: this.headers});
    }

  }

  public getObjectNotes(id: string): Observable<any> {
    return this.http.get(this.apiURL + "/hv/object/" + id + "/note", {
      headers: this.headers,
    });
  }

  public getObjectFiles(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/hv/object/' + id + '/file', {headers: this.headers});
  }

  public getRoomsByObject(objectId: string): Observable<any> {
    return this.http.get(this.apiURL + "/hv/object/" + objectId + "/rooms", {headers: this.headers});
  }

  public getTicketsByState(stateId: string, httpParams?: HttpParams): Observable<any> {
    return this.http.get(this.apiURL + '/hv/ticket/state/' + stateId, {headers: this.headers, params: httpParams});
  }

  public getTicket(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/hv/ticket/' + id, {headers: this.headers});
  }

  public getStates(): Observable<any> {
    return this.http.get(this.apiURL + '/hv/state/', {headers: this.headers});
  }

  public getTicketImage(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/hv/ticket/' + id + '/image', {headers: this.headers});
  }

  public deleteImage(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/hv/image/' + id, {headers: this.headers});
  }

  public getTicketFiles(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/hv/ticket/' + id + '/file', {headers: this.headers});
  }

  public getTicketConsumables(ticketId: string): Observable<any> {
    return this.http.get(this.apiURL + '/hv/ticket/' + ticketId + '/consumables', {headers: this.headers});
  }

  public getTicketNotes(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/hv/ticket/' + id + '/note', {headers: this.headers});
  }

  public createTicketNote(ticketId: string, data: TicketNote): Observable<any> {
    return this.http.post(this.apiURL + '/hv/ticket/' + ticketId + '/note', data, {headers: this.headers});
  }

  public deleteTicketNotes(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/hv/note/' + id, {headers: this.headers});
  }

  public downloadImage(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/hv/image/' + id + '/download', {headers: this.headers, responseType: 'blob'});
  }

  public getUsers(id?: string): Observable<any> {
    if (!id) {
      return this.http.get(this.apiURL + '/hv/user/', {headers: this.headers});
    } else {
      return this.http.get(this.apiURL + '/hv/user/' + id, {headers: this.headers});
    }
  }

  public getPropertyManagementRequest(id?: string): Observable<any> {
    if (!id) {
      return this.http.get(this.apiURL + '/hv/property-management-request', {headers: this.headers});
    } else {
      return this.http.get(this.apiURL + '/hv/property-management-request/' + id, {headers: this.headers});
    }
  }

  public setPropertyManagementRequestAction(propertyManagementRequest: PropertyManagementRequest, action: PropertyManagementRequestAction): Observable<any> {
    return this.http.post(this.apiURL + '/hv/property-management-request/' + action, propertyManagementRequest, {headers: this.headers});
  }

  public deleteObjectConnection(objectId: string): Observable<any> {
    return this.http.delete(this.apiURL + '/hv/property-management-connection/' + objectId, {headers: this.headers});
  }

  public createTicket(data: Ticket | FormData): Observable<any> {
    return this.http.post(this.apiURL + '/hv/ticket/', data, {headers: this.headers});
  }

  public getHVWorkLog(id: string): Observable<any> {
    return this.http.get(this.apiURL + "/getHVWorkLog/" + id, { headers: this.headers });
  }

  public updateTicketField(ticket: Ticket, field: string, value: any): Observable<any> {
    return this.http.post(this.apiURL + '/hv/ticket/' + ticket.id + '/field/' + field, {value}, {headers: this.headers}
    );
  }

}
