import { Component, OnInit } from '@angular/core';
import {ThemeConstantService} from '../../services/theme-constant.service';
import {AuthenticationService} from '../../services/authentication.service';
import {ROUTES} from './side-nav-hausverwaltung-routes.config';

@Component({
  selector: 'app-side-nav-hausverwaltung',
  templateUrl: './side-nav-hausverwaltung.component.html'
})
export class SideNavHausverwaltungComponent implements OnInit {


  public menuItems: any[];
  isFolded: boolean;
  isSideNavDark: boolean;

  constructor(private themeService: ThemeConstantService, private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    if (this.authenticationService.currentUserValue.isAdmin) {
      this.menuItems = ROUTES.filter(menuItem => menuItem);
    } else {
      this.menuItems = ROUTES.filter(menuItem => {
        return !menuItem.adminOnly;
      });
    }

    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
  }
}
