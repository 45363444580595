<div class="header">
  <div class="logo logo-dark">
    <a href="">
      <img src="assets/images/logo/logo-text-only.svg" alt="Logo">
      <img class="logo-fold" src="assets/images/logo/logo-fold.svg" alt="Logo">
    </a>
  </div>
  <div class="nav-wrap">
    <ul class="nav-left">
      <li class="desktop-toggle">
        <a (click)="toggleFold()">
          <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" nzTheme="outline"></i>
        </a>
      </li>
      <li class="mobile-toggle">
        <a (click)="toggleExpand()">
          <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" nzTheme="outline"></i>
        </a>
      </li>
      <!-- <li>
           <a (click)="searchToggle()">
               <i nz-icon nzType="search" theme="outline"></i>
           </a>
           <nz-drawer [nzWidth]="380" nzTitle="Search" [nzVisible]="searchVisible" nzPlacement="left" (nzOnClose)="searchToggle()">
               <app-search></app-search>
           </nz-drawer>
       </li>-->
    </ul>
    <ul class="nav-right">
      <li>
        <a nz-dropdown nzTrigger="click" (click)="notificationService.getNotifications()"
           [nzDropdownMenu]="popNotification" [nzPlacement]="'bottomRight'">
          <nz-badge [nzCount]="notificationService.unreadNotifications">
            <i nz-icon nzType="bell" theme="outline"></i>
          </nz-badge>
        </a>
        <nz-dropdown-menu #popNotification="nzDropdownMenu">
          <div nz-menu class="pop-notification">
            <div class="p-v-15 p-h-25 border-bottom d-flex justify-content-between align-items-center">
              <p class="text-dark font-weight-semibold m-b-0">
                <i nz-icon nzType="bell" theme="outline"></i>
                <span class="m-l-10">Benachrichtigungen</span>
              </p>
              <a nz-button nz-button nzSize="small" class="p-v-5" [routerLink]="'/notifications/my-notifications'">
                <small>Alle Anzeigen</small>
              </a>
            </div>
            <div class="overflow-y-auto scrollbar-wrapper" style="max-height: 300px">
              <nz-list class="ant-list-item-links" [nzDataSource]="notificationService.notificationList"
                       [nzRenderItem]="item" [nzItemLayout]="'horizontal'">
                <ng-template #item let-item>
                  <nz-list-item [class.notification-read]="!item.read">
                    <a [routerLink]="'/hv/' + item.actionURL">
                      <div class="d-flex" (mouseover)="notificationService.setNotificationToRead(item)">
                        <div class="m-l-15">
                          <p class="m-b-0 text-dark">{{item.title}}</p>
                          <p class="m-b-0"><small>Vor {{getTime(item.createdAt)}}</small></p>
                        </div>
                      </div>
                    </a>
                  </nz-list-item>
                </ng-template>
              </nz-list>
            </div>
          </div>
        </nz-dropdown-menu>
      </li>

      <li>
                <span class="p-h-10 pointer" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'"
                      [nzPlacement]="'bottomRight'">
                    <nz-avatar [nzIcon]="'user'" nzSrc=""></nz-avatar>
                </span>
        <nz-dropdown-menu #profile="nzDropdownMenu">
          <ul nz-menu class="p-b-15 p-t-20">
            <li class="p-h-20 p-b-15 m-b-10 border-bottom">
              <div class="d-flex m-r-50">
                <nz-avatar nzSize="large" nzSrc=""></nz-avatar>
                <div class="m-l-10">
                  <p class="m-b-0 text-dark font-weight-semibold">{{user.name}}</p>
                  <p class="m-b-0 opacity-07">{{user.email}}</p>
                </div>
              </div>
            </li>
            <li nz-menu-item [routerLink]="'/hv/account'">
              <a  class="p-v-5 d-flex align-items-center justify-content-between">
                <div>
                  <i class="opacity-04 font-size-16" nz-icon nzType="lock" nzTheme="outline"></i>
                  <span class="m-l-10">Account Einstellungen</span>
                </div>
                <i class="font-size-10" nz-icon nzType="right" nzTheme="outline"></i>
              </a>
            </li>
            <li nz-menu-item (click)="authservice.logout()">
              <a  class="p-v-5 d-flex align-items-center justify-content-between">
                <div>
                  <i class="opacity-04 font-size-16" nz-icon nzType="logout" nzTheme="outline"></i>
                  <span class="m-l-10">Logout</span>
                </div>
                <i class="font-size-10" nz-icon nzType="right" nzTheme="outline"></i>
              </a>
            </li>
          </ul>
        </nz-dropdown-menu>
      </li>
      <li *ngIf="false">
        <a (click)="quickViewToggle()">
          <i nz-icon nzType="appstore" nzTheme="outline"></i>
        </a>
        <nz-drawer [nzWidth]="280" nzTitle="Quick View" [nzClosable]="false" [nzVisible]="quickViewVisible"
                   nzPlacement="right" (nzOnClose)="quickViewToggle()">
          <app-quick-view></app-quick-view>
        </nz-drawer>
      </li>
    </ul>
  </div>
</div>
