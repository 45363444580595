import {Component, OnInit} from '@angular/core';
import {ThemeConstantService} from '../../services/theme-constant.service';
import {AuthenticationService} from '../../services/authentication.service';
import {Notification, User} from '../../../../../../database-models';
import {ApiService} from '../../../services/api.service';
import {MessageService} from '../../services/message.service';
import moment from 'moment';
import {NotificationService} from '../../services/notification.service';
import {HausverwaltungApiService} from '../../../hausverwaltung/service/hausverwaltung-api.service';

@Component({
  selector: 'app-header-hausverwaltung',
  templateUrl: './header-hausverwaltung.component.html',
  styleUrls: ['./header-hausverwaltung.component.scss']
})

export class HeaderHausverwaltungComponent implements OnInit {

  searchVisible = false;
  quickViewVisible = false;
  isFolded: boolean;
  isExpand: boolean;
  public user: User;

  constructor(private themeService: ThemeConstantService,
              public authservice: AuthenticationService,
              private api: HausverwaltungApiService,
              private message: MessageService,
              public notificationService: NotificationService) {
    this.authservice.currentUserSubject.subscribe(user => {
      this.user = user;
    });
    this.getCurrentUser();
    setInterval(() => {
      this.getCurrentUser();
    }, 60000);
  }

  public getCurrentUser(): void {
    this.api.getUsers('me').subscribe((user: User) => {
      this.authservice.setCurrentUser(user);
      this.user = user;
    }, onerror => {
      console.log(onerror);
    });

  }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
  }

  toggleFold() {
    this.isFolded = !this.isFolded;
    this.themeService.toggleFold(this.isFolded);
  }

  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isFolded);
  }

  quickViewToggle(): void {
    this.quickViewVisible = !this.quickViewVisible;
  }


  public getTime(date: Date): string {
    const momentDate = moment(date);
    return momentDate.locale('de').fromNow(true).toString();
  }
}
